$('document').ready(function () {
  $('.profile-gld-role #gld-division').hide();
  
  $('.profile-gld-role #legal').click(function () {
    $('.profile-gld-role #gld-division').show();
    $('.profile-gld-role #gld-division .select-option').prop('required', true);
  });
  
  $('.profile-gld-role #non_legal').click(function () {
    $('.profile-gld-role #gld-division').hide();
    $('.profile-gld-role #gld-division .select-option').prop('required', false);
  });
});



